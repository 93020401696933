.breadcrumb {
    background-color: $color-bg--neutral;

    ul {
        // display: block;
        // min-height: 60px;
        // padding: 0;
    
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        min-height: 60px;
        margin-bottom: 15px;
        gap: 10px 0;
    }

    li {
        position: relative;
        display: inline;
        color: $color-text;
        // line-height: 1.75;

        a, span {
            display: inline-block;
            // height: 45px;
            color: $color-text;
            font-size: toRem(16);
            line-height: toRem(25);
            font-weight: $font-weight-light;
            background-color: $color-bg--transparent;
            border: $border-width $border-style $color-white;
            border-radius: 40px;
            margin: 0;
            padding: 10px 15px;
            transition: {
                property: background-color, border-color;
                duration: $duration;
                timing-function: $timing;
            }
            text-decoration: none;
        }

        a {
            &:hover, &:focus {
                color: $color-text;
                border-color: $color-white;
                background-color: $color-white;
            }
        }
    }

    svg {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 5px;
        fill: $color-text;
        vertical-align: text-top;
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .breadcrumb {
        li svg {
            margin: 0;
        } 
    }

}


// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        li svg {
            margin: 0 -1px;
        } 
    }

}
