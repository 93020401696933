.cover {
    position: relative;
    padding-top: 130px;
    background-color: $color-light;
    border-radius: 0px 0px 50px 50px;

    &__image-wrapper {
        @include absolute($top: 0, $left: 10px);
        @include size(500px, 100%);
        min-height: 300px;
        background-color: $color-bg--image;
        border-radius: $border-radius;
        overflow: hidden;

        picture {
            display: block;
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            display: block;
        }

        & ~ .cover__content-wrapper {
            width: 630px;
            margin-left: calc(500px + 40px);
        }
    }

    &__content-wrapper {
        @include size(100%, auto)
    }

    &__informations {
        padding: 60px 0;
        
        .container {
            display: flex;
            position: relative;
            align-items: center;
            min-height: 300px;
            column-gap: 40px;
        }
    }

    &__title {
        padding: 0;
        margin: 0 0 15px;
    }

    &__lead-wrapper {
        p {
            margin: 15px 0 0;
            font-weight: $font-weight-bold;
            font-size: toRem(18);
            line-height: toRem(25);
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .cover {
        .cover {
            &__informations {
                padding-top: 0;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {

    .cover {
        border-radius: 0 0 25px 25px;
        
        &__image-wrapper {
            @include size(42.85%, 100%);
        
            & ~ .cover__content-wrapper {
                width: 57%;
                margin-left: calc(44% + 30px);
            }
        }
    }

}


// 800
@media screen and (max-width: 800px) {
    .cover {
        border-radius: 0;

        .cover {
            &__informations {
                padding-top: 0;

                .container {
                    flex-direction: column;
                }
            }

            &__image-wrapper {
                    position: initial;
                    @include size(100%, 255px);
                    margin-bottom: 15px;

                & ~ .cover__content-wrapper {
                    width: 100%;
                    margin-left: 0;
                }
            }

            &__title {
                font-size: toRem(25);
                line-height: toRem(30);
                margin-top: 10px;
            }

            &__intro {
                font-size: toRem(15);
                line-height: toRem(20);
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {

    .cover {
        
        &__image-wrapper {
            height: 180px;
        }
    }

}
