body.home {
    header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 5;
        width: 100%;

        .usefull-links {
            right: 375px;
            top: 55px;
        }

        .tools--burger {
            top: 55px;
        }

        .tools__item {
            background-color: $color-light;

            &:hover, &:focus {
                background-color: $color-main;

                svg {
                    fill: $color-white;
                }
            }
        }

        & .nav-main--burger {
            & .nav-main-buttons {
                &__menu {
                    top: 55px;
                }
            }
        }
    }
}

header {
    position: fixed;
    z-index: 5;
    width: 100%;

    &:before {
        content: "";
        position: fixed;
        z-index: -1;
        top: -80px;
        @include size(100%, 80px);
        border-radius: 0px 0px $border-radius $border-radius;
        background-color: $color-white;
        box-shadow: $shadow;
        transition: top $duration $timing;
    }

    &.header--fixed {

        &:before {
            top: 0;
        }

        .identity {
            box-shadow: $shadow;
        }

        .usefull-links {
            top: 20px;
        }

        .tools--burger {
            top: 20px;
        }

        .tools__item {
            background-color: $color-light;

            &:hover, &:focus {
                background-color: $color-main;
            }
        }

        & .nav-main--burger {
            & .nav-main-buttons {
                &__menu {
                    top: 20px;
                }
            }
        }
    }
}

//======================================================================================================
// Identity
//======================================================================================================
.identity {
    @include flex($alignItems: center, $justifyContent: center);
    @include size(fit-content, 90px);
    color: $color-text;
    padding: 20px 30px 25px;
    background-color: $color-white;
    border-radius: 0 0 25px 25px;
    transition: color $duration $timing;

    &:hover, &:focus {
        color: $color-second;
    }

    &__title {
        margin: 0;
        font-weight: 0;
        line-height: 0;
        text-transform: uppercase;
    }
}


//======================================================================================================
// Usefull links
//======================================================================================================
.usefull-links {
    display: flex;
    align-items: center;
    position: absolute;
    right: 425px;
    top: 30px;
    transition: top $duration $timing;

    .usefull-link {
        border: 0;
        background-color: $color-dark;
        margin: 0 0 0 10px;
        padding: 0;
        transition: {
            property: background-color,  color;
            duration: $duration;
            timing-function: $timing;
        }

        svg {
            @include size(40px);
            transition: fill $duration $timing;
        }

        &--app {
            display: flex;
            align-items: center;
            padding: 10px 14px;
            font-size: toRem(18);
            line-height: toRem(20);
            
            svg {
                @include size(20px);
                margin-right: 5px;
            }
        }

        &:hover, &:focus {
            color: $color-white;
            background-color: $color-main;
        
            svg {
                fill: $color-white;
            }
        }
    }
}

//======================================================================================================
// Tools
//======================================================================================================
.tools {

    &--burger {
        position: absolute;
        right: 155px;
        top: 30px;
        transition: top $duration $timing;
    }

    &__content {
        display: flex;
    }

    &__item {
        @include flex($justifyContent: center, $alignItems: center);
        @include size(40px);
        margin: 0 0 0 10px;
        padding: 0;
        background-color: $color-white;
        border: 0;
        cursor: pointer;

        &--contact {
            font-size: toRem(18);
            line-height: toRem(20);
            color: $color-text;
            width: auto;
            padding: 10px 20px;

            svg {
                @include size(20px);
                margin-right: 10px;
            }
        }

        svg {
            fill: $color-text;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            color: $color-white;

            svg {
                fill: $color-white;
            }
        }

        // &--translate {
        //     svg {
        //         @include size(24px);
        //     }
        // }

        &--accessibility {
            svg {
                @include size(40px);
            }
        }

        // &--profile {
        //     svg {
        //         @include size(29px, 22px);
        //     }
        // }

        &--search {
            svg {
                @include size(40px);
            }

            &--disabled {
                display: none;
                // pointer-events: none;
                // opacity: .2;
            }
        }
    
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    body.home {
        header {
            .usefull-links, .tools--burger {
                top: 35px;
            }

            .usefull-links {
                right: 325px;
            }

            .usefull-link {
                &--app {
                    font-size: toRem(16);
                    line-height: toRem(20);
                    padding: 10px 15px;
                }
            }

            .nav-main--burger {
                .nav-main-buttons {
                    &__menu {
                        top: 35px;
                    }
                }
            }
        }
    }

    .usefull-links {
        right: 375px;
    }

    .tools {
        &--burger {
            right: 128px;
        }
    }    

    .tools__item {
        &--contact {
            padding: 10px 15px;
            font-size: toRem(16);
            line-height: toRem(20);
        }
    }

    .identity {
        &.identity--burger {
            padding: 20px 30px;

            & .identity {
                &__title {
                    & svg {
                        @include size(125px, 60px);
                    }
                }
            }
        }
    }
    
}


// 960
@media screen and (max-width: $medium) {

    body.home {
        header {
            .usefull-links {
                right: calc(5% + 325px);
            }
        }
    }

    .usefull-links {
        right: calc(5% + 375px);
    }

    .tools--burger {
        right: calc(5% + 128px);
    }

}


// 900
@media screen and (max-width: 900px) {
    header {
        position: relative;
        margin-bottom: -100px;

        .header__container {
            display: flex;
            justify-content: center;
        }

        .tools--burger {
            position: unset;

            .tools__item {

                &--accessibility {
                    margin: 0;
                }

                &--contact {
                    font-size: toRem(0);
                    line-height: toRem(0);
                    padding: 10px;

                    svg {
                        margin-right: 0;
                    }
                }
            }
        }

        .nav-main--burger {
            &.js-open-menu {
                .nav-main-buttons {
                
                    .usefull-links {        
                        left: calc(5% + 10px)!important;
                        right: unset;
                    }
                }

                &.js-open-submenu {
                    .nav-main-buttons {
                        .usefull-links {
                            left: -100vw!important;
                        }
                    }
                }
            }
        }
        
        .usefull-links {
            right: unset!important;
            left: -100vw;
            bottom: 80px;
            position: fixed;
            top: unset!important;
            height: fit-content;
            transition: left $duration $timing;
            
            .usefull-link {
                border: $border-width $border-style $color-white;
        
                &--facebook {
                    margin-left: 0;
                }
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {

}
