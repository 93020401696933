.nav-main--burger {
    z-index: inherit;

    // Burger menu Button
    .nav-main-buttons {
        position: relative;

        &__menu {
            z-index: 11;
            @include absolute($top: 30px, $right: 15px);
            @include flex($justifyContent: flex-start, $alignItems: center);
            column-gap: 10px;
            width: fit-content;
            margin: 0;
            padding: 10px 20px;
            background-color: $color-second;
            border: 0;
            border-radius: 10px;
            transition: all $duration $timing;
            cursor: pointer;

            &__text {
                font-size: toRem(18);
                line-height: toRem(20);
                font-weight: $font-weight-bold; 
                color: $color-text;
                text-transform: uppercase;
                transition: all $duration $timing;
            }

            &__svg {
                fill: $color-text;
                @include size(20px);
            }

            &:hover, &:focus {
                // background-color: $color-dark;
                
                &.nav-main-buttons {
                    
                    &__menu {

                        &--open {
                            background-color: $color-dark;

                            & .nav-main-buttons {
                                &__menu {
                                    &__text {
                                        color: $color-white;
                                    }
            
                                    &__icon {
                                        background-color: $color-white;
            
                                        &:before, &::after {
                                            background-color: $color-white;
                                        }
                                    }
                                }
                            }
                        }

                        &--close {
                            background-color: $color-white;

                            & .nav-main-buttons {
                                &__menu {
                                    &__text {
                                        color: $color-dark;
                                    }
                                    
                                    &__svg {
                                        fill: $color-dark;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // Open menu
            &--open {
                width: 125px;
                opacity: 1;
                visibility: initial;
                pointer-events: auto;
                transition: all $duration $timing;

                // Burger menu Button icon
                .nav-main-buttons__menu__icon {
                    position: relative;
                    top: -1px;
                    display: block;
                    @include size(20px, 2px);
                    background-color: $color-text;
                    transition: all $duration $timing;

                    // &::before, 
                    // &:after {
                    //     content: '';
                    //     position: absolute;
                    //     display: block;
                    //     @include size(100%);
                    //     background-color: $color-text;
                    //     transition: all $duration $timing;
                    // }

                    // &::before {
                    //     top: 7px;
                    // }

                    // &::after {
                    //     top: -7px;
                    // } 
                }
            }

            // Close menu
            &--close {
                column-gap: 0;
                padding: 0 20px 0 10px;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                transition: all $duration $timing;

                svg {
                    @include size(40px);
                    fill: $color-text;
                    transition: all $duration $timing;
                }
            }
        }

        // For responsive
        &__submenu {
            display: none;
        }

    }

    // Popup menu
    &__popup {
        @include fixed($top: -100vh, $left: 0, $right: 0);
        z-index: 10;
        display: block;
        max-width: inherit;
        @include size(100%, 100vh);
        background-color: $color-dark;
        padding: 0;
        transition: {
            property: top, left;
            duration: .5s, $duration;
            timing-function: $timing;
        }

        // &::before {
        //     content: '';
        //     @include absolute($top: 0, $left: 0, $right: 20px);
        //     z-index: 1;
        //     height: 170px;
        //     background-color: $color-main;
        // }
    }

    // Section menu (for overflow)
    &__section {
        overflow-y: auto;
        height: 100%;
        padding: 40px 0 20px 0;
    }

    // Container menu
    &__container {
        @include flex($justifyContent: space-between, $alignItems: flex-start);
    }

    // if Burger menu open
    &.js-open-menu {

        // Burger menu Button
        .nav-main-buttons__menu {

            // Close menu
            &--close {
                opacity: 1;
                visibility: initial;
                pointer-events: auto;
            }

            // Open menu
            &--open {
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
            }
        }

        // Popup menu
        .nav-main--burger__popup {
            top: 0;
            transition: top .7s cubic-bezier(0.4, 0, 0.2, 1);
        }

        // Main menu
        .menu {
            position: relative;
            display: flex;
        }

    }

    // Main menu
    .menu {
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 50px;
        @include size(405px, auto);
        margin-top: 130px;
        margin-bottom: 40px;
        padding: 0 50px 0 0;

        &__item {
            @include flex($alignItems: center, $justifyContent: space-between);
            width: 100%;

            &.menu-item-has-children {
                &.js-open-submenu {

                    & .menu__link {
                        
                        opacity: 1;
                        padding-left: 20px;

                        &::before {
                            opacity: 1;
                            left: 0;
                        }
                    }

                    & .submenu {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }
            }
        }

        &__link {
            position: relative;
            @include flex($alignItems: center, $justifyContent: space-between);
            max-width: inherit;
            @include size(100%, auto);
            padding: 0;
            font-size: toRem(22);
            line-height: toRem(30);
            font-weight: $font-weight-bold;
            color: $color-white;
            opacity: .5;
            text-transform: uppercase;
            transition: {
                property: padding-left, opacity;
                duration: $duration;
                timing-function: $timing;
            }

            &::before {
                content: '';
                @include absolute($top: 0, $left: -10px, $bottom: 0);
                @include size(10px);
                border-radius: $border-radius--round;
                background-color: $color-second;
                margin: auto 0;
                opacity: 0;
                transition: {
                    property: left, opacity;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                opacity: 1;
                padding-left: 20px;

                &::before {
                    opacity: 1;
                    left: 0;
                }
            }
        }
    }

    // Sub menu
    .submenu {
        opacity: 0;
        pointer-events: none;
        @include absolute($top: 0, $left: 100%, $right: inherit);
        z-index: inherit;
        width: 545px;
        padding: 0 0 0 95px;
        background-color: $color-bg--transparent;
        box-shadow: none;
        transition: {
            property: opacity;
            duration: $duration;
            timing-function: $timing;
        }

        &::before {
            content: '';
            @include absolute($top: 0, $left: 0);
            @include size(1px, 100%);
            background-color: $color-white;
        }

        // &::after {
        //     content: "";
        //     @include absolute($top: 0, $left: -1px);
        //     @include size(1px, 100%);
        //     background-color: $color-second;
        // }

        &__nav {
            @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: flex-start);
            gap: 5px 30px;
            max-width: inherit;
            // max-height: 80vh;
            padding: 0 0 30px;
        }

        &__item {
            width: 100%;
            margin: 0;         
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            @include size(auto);
            margin-bottom: 1px; // Fix space beetween inline-flex
            padding: 10px 0 9px;
            color: $color-white;
            font-weight: $font-weight-medium;
            width: 100%;
            justify-content: space-between;

            &:after {
                content: "";
                display: inline-block;
                @include size(26px);
                background-color: $color-white;
                mask-image: url( $urlShort + '/ic-nav-chevron-right.svg');
                mask-repeat: no-repeat;
                margin-left: 5px;
                opacity: .5;
                transition: {
                    property: background-color, opacity;
                    duration: $duration;
                    timing-function: $timing;
                }
            }
            
            &:hover, &:focus {
                color: $color-second;

                &:after {
                    opacity: 1;
                    background-color: $color-second;
                }
            }
        }
    }

}

// Button accessibility
button.avoid-main-content {
    @include absolute($bottom: 40px, $right: 40px);
    pointer-events: none;

    &:focus {
        color: $color-white;
        border-color: $color-white;
    }
}

// For no scroll on body when menu is open
body.js-open-menu {
    overflow: hidden;
}


//=================================================================================================
// Header only for burger menu on demosaisie
//=================================================================================================

// Identity
.identity.identity--burger {
    position: fixed;
    // z-index: 11;
    width: fit-content;
    height: auto;
    max-width: 85%;
    min-height: 90px;

    .identity__title {
        transition: {
            property: margin-top, color;
            duration: $duration;
            timing-function: $timing;
        }
    }
}

//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main--burger {
        .nav-main-buttons {
            &__menu {
                top: 30px;
                right: 10px;
                padding: 10px 15px;

                &--open {
                    width: 108px;
                }

                &--close {
                    padding: 0 15px 0 5px;
                }

                &__text {
                    font-size: $font-size--text;
                }
            }
        }

        // Main menu
        .menu {
            width: 30%;
            margin-top: 150px;
            padding: 0 30px 0 0;
        }

        // Sub menu
        .submenu {
            width: 65vw;
            padding: 0 30px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {
    
    // Header -- Identity
    .identity.identity--burger {
        position: inherit;
        z-index: inherit;
        max-width: 100%;
        height: auto;
    }
    body.js-open-menu .identity__title {
        margin-top: 0;
        color: $color-text;
    }
    
    // Menu
    .nav-main--burger {

        // if Burger menu open
        &.js-open-menu {

            // Popup menu
            .nav-main--burger__popup {
                left: 0;
                transition: left $duration $timing;
            }

            .nav-main-buttons__menu--close {
                right: calc(5% + 10px);
            }

            // And If submenu open
            .nav-main-buttons.js-open-submenu {
                .nav-main-buttons__submenu {
                    left: calc(5% + 10px);
                }
            }
        }

        // If submenu open
        &.js-open-submenu {
            .nav-main--burger__section {
                overflow: hidden;
            }
        }

        // Burger Buttons
        .nav-main-buttons {
            position: inherit;
            @include flex($alignItems: center, $justifyContent: flex-start);
            height: 69px;

            // Open menu
            &__menu {
                right: calc(5% + 10px);

                // Close menu
                &--close {
                    top: 30px!important;
                    right: 100vw;
                    padding: 0!important;

                    .nav-main-buttons__menu__text {
                        display: none;
                    }
                }

            }

            // Burger menu responsive - Close submenu
            &__submenu {
                @include absolute($top: 30px, $left: -100vw);
                z-index: 11;
                @include flex($alignItems: center, $justifyContent: center);
                flex-shrink: 0;
                @include size(40px);
                margin: 0;
                padding: 0;
                background-color: $color-second;
                border: 0;
                border-radius: 10px;
                transition: left $duration $timing;

                svg {
                    @include size(40px);
                    fill: none;
                    stroke: $color-text;
                    stroke-width: 2px;
                }
            }
        }

        // Popup menu
        &__popup {
            top: 0;
            right: inherit;
            left: -100vw;
            padding: 0;

            &::before {
                height: 100px;
            }
        }

        // Section menu
        &__section {
            padding: 0;
        }

        // Container menu
        &__container {
            max-width: initial;
            margin: 0;
            padding: 0;
        }

        // Main menu
        .menu {
            // Test
            display: flex;
            flex-wrap: nowrap;
            @include size(100%, auto);
            min-height: 100vh;
            margin: 0;
            padding: 100px calc(5% + 10px) 40px;
            gap: 30px;
            overflow: hidden;

            &::after {
                display: none;
            }

            &__item {

                .menu__link {
                    opacity: 1;
                    padding-left: 20px;
                
                    &:before {
                        left: 0;
                        opacity: 1;
                    }
                }
                
                &.menu-item-has-children {

                    &:focus-within .submenu,
                    &:hover .submenu {
                        display: flex;
                    }

                    &.js-open-submenu {

                        .submenu {   
                            left: -100vw;
                            display: flex;
                        }
                    }
                }
            }
        }

        &.js-open-menu {
            .menu {
                &__item {
                    &.menu-item-has-children {
                        &.js-open-submenu {
                            .submenu {
                                display: flex;
                                left: 0;
                            }
                        }
                    }
                }
            }
        }

        // Sub menu
        .submenu {
            @include fixed($top: 0, $right: inherit, $left: -100vw);
            z-index: 1;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            @include size(100vw, 100vh);
            padding: 100px 0 0;
            background-color: $color-dark;
            transition: left $duration $timing;

            &::before, &::after {
                display: none;
            }
    
            &__nav {
                @include flex($direction: column, $justifyContent: flex-start, $wrap: nowrap);
                gap: initial;
                @include size(100%, calc(100vh - 100px));
                max-width: 100%;
                max-height: inherit;
                overflow-y: auto;
                padding: 5px calc(5% + 10px) 40px;  
            }

            &__item {
                width: 100%; 
            }

            &__link {
                width: 100%;

                &::before {
                    display: none;
                }
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main--burger {
            .menu, .submenu {
                padding-top: 132px;
            }

            &__popup::before {
                height: 132px;
            }
        }
    }
}


// 900
@media screen and (max-width: 900px) {

    .nav-main--burger {
        .nav-main-buttons {
            position: fixed;
            z-index: 11;
            left: 0;
            right: 0;
            bottom: 0;
            @include size(100%, 60px);
            max-width: unset;
            background: $color-dark;
            border-radius: 20px 20px 0 0;
            padding: 0 calc(5% + 10px);
            justify-content: space-between;

            .nav-main-buttons {
            
                &__menu {
                
                    &--open {
                        position: unset;
                    }

                    &--close {
                        position: fixed;
                        padding: 0;
                        top: 30px;
                    }
                }

                &__submenu {
                    position: fixed;
                    top: 30px;
                }
            }
        }

        &.js-open-menu {
            
            .tools--burger {
                opacity: 0;
            }
        }
    }
}


// 782 -- For admin bar only
@media screen and (max-width: 782px) {

    // Admin 
    .admin-bar {
        .nav-main--burger {
            .menu, .submenu {
                padding-top: 146px;
            }
            
            &__popup::before {
                height: 146px;
            }
        }
    }

}
