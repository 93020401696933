body.home {
    position: relative;
    height: 100%;
    min-height: 800px;
    max-height: 100vh;

    main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 170px;
        padding-bottom: 115px;
        height: 100vh;
        min-height: 800px;
    }
}

// Zone libre 2 - Grande Image
.gdimage {
    z-index: -1;
    @include position(absolute, $top: 0, $bottom: 0, $left: 0);
    @include size(calc(50% - 50px), 100%);

    img {
        display: block;
        @include size(100%);
        object-position: top;
        object-fit: cover;
    }
}

// Section
.section {

    & .container {
        display: flex;
    }

    &--1 {
        position: relative;
        z-index: 1;
        margin-bottom: 40px;

        & .container {
            justify-content: space-between;
        }
    }

    &--2 {
        & .container {
            justify-content: flex-end;
            column-gap: 30px;
        }
    }
}

// Zone libre 1 - Seach Bar + Admin buttons

.search_home {
    @include size(670px, auto);
    padding: 45px 35px;
    border-radius: $border-radius;
    background-color: $color-light;

    &__label {
        position: relative;
        padding-left: 20px;
        font-size: toRem(28);
        line-height: toRem(30);
        font-weight: $font-weight-bold;
        margin: 0 0 20px;

        &:before {
            content: "";
            @include position(absolute, $top: 10px, $left: 0);
            @include size(10px);
            border-radius: $border-radius--round;
            background-color: $color-dark;
        }
    }

    &__bar {
        position: relative;
        margin-bottom: 20px;

        .searchResult {
            position: absolute;
            width: 100%;
            top: 29px;
            margin-top: 0;
        }
    }

    .search-svg {
        @include size(40px)
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;
    }

    &__button {
        color: $color-text;
        font-size: toRem(14);
        line-height: toRem(20);
        font-weight: $font-weight-semibold;
        background-color: $color-bg--transparent;
        border: $border-width $border-style $color-white;
        border-radius: 40px;
        margin: 0;
        transition: {
            property: background-color, border-color;
            duration: $duration;
            timing-function: $timing;
        }

        svg {
            @include size(20px);
            fill: $color-text;
        }

        &:hover, &:focus {
            color: $color-text;
            border-color: $color-white;
            background-color: $color-white;

            svg {
                fill: $color-text;
            }
        }
    }

    div.search_home__button {
        pointer-events: none;
    }
}

// Accès rapide
.access {
    @include size(435px, 260px);

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px 60px;
    }

    &__item {
        @include size(105px, 120px);
    }

    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;

        svg {
            fill: $color-white;
            @include size(75px);
            background-color: $color-main;
            border-radius: $border-radius--round;
            transition: {
                property: background-color, fill;
                duration: $duration;
                timing-function: $timing;
            }
        }

        &:hover, &:focus {
            
            svg {
                fill:$color-text;
                background-color: $color-light;
            }
        }
    }

    &__label {
        padding-top: 5px;
        text-align: center;
        text-transform: uppercase;
        font-size: toRem(14);
        line-height: toRem(16);

        &__span {
            display: block;
            font-weight: $font-weight-bold;
        }
    }
}

// Events
.events {
    @include size(260px, 255px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    &--empty {
        display: none;
    }

    &__link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include size(260px, 200px);
        gap: 10px;
        padding: 20px 25px;
        background-color: $color-fourth;
        border-radius: $border-radius;

        &:hover, &:focus {

            .events {
                &__date {
                    padding-left: 30px;

                    &::before {
                        width: 20px;
                    }
                }
            }
                        
        }
    }

    &__date {
        position: relative;
        font-size: toRem(18);
        line-height: toRem(25);
        padding-left: 20px;
        text-transform: uppercase;
        transition: padding-left $duration $timing;

        &:before {
            content: "";
            @include position(absolute, $top: 7px, $left: 0);
            @include size(10px);
            margin: 0 auto;
            border-radius: 10px;
            background-color: $color-second;
            transition: width $duration $timing;
        }
    }

    &__title {
        font-size: toRem(22);
        line-height: toRem(25);
        margin: 0;
    }

    &__intro {
        font-size: toRem(14);
        line-height: toRem(20);
    }

    &__button {
        padding-top: 0;
        padding-right: 10px;
        padding-bottom: 0;
        margin: 0;
        border: 0;

        svg {
            @include size(40px)
        }
    }
}

// Posts
.posts {
    @include size(540px, 255px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    &--empty {
        display: none;
    }

    &__link {
        display: flex;
        background-color: $color-light;
        border-radius: $border-radius;
        overflow: hidden;

        &:hover, &:focus {
            .posts {
                &__image {
                    & img {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }

    &__image {
        @include size(260px, 200px);
        overflow: hidden;

        & img {
            @include size(100%);
            object-fit: cover;
            object-position: top;
            transform: scale(1);
            transition: transform $duration $timing;
        }

        & ~ .posts__content {
            width: 280px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px 25px;
        @include size(100%, 200px)
    }

    &__title {
        font-size: toRem(22);
        line-height: toRem(25);
        margin: 0 0 5px;
    }

    &__intro {
        font-size: toRem(14);
        line-height: toRem(20);
    }

    &__button {
        padding-top: 0;
        padding-right: 10px;
        padding-bottom: 0;
        margin: 0;
        border: 0;

        svg {
            @include size(40px)
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    body.home {
        min-height: 710px;

        main {
            padding-top: 130px;
            padding-bottom: 100px;
            min-height: 710px;
        }
    }

    .section {
        &--1 {
            margin-bottom: 35px;
        }

        &--2 {
            .container {
                column-gap: 20px;
            }
        }
    }
 
    // Grande image
    .gdimage {
        width: calc(50% - 65px);
    }
    
    // Search bar
    .search_home {
        @include size(550px, auto);
        padding: 40px 30px;

        label {
            font-size: toRem(22);
            line-height: toRem(25);
            padding-left: 17px;

            &:before {
                top: 7px;
            }
        }

        .search-container {
            #searchInput {
                height: 50px;
            }
        }

        &__button {
            padding: 10px;
            font-size: toRem(12);
            line-height: toRem(20);
        }
    }

    // Access
    .access {
        @include size(355px, 225px);

        &__list {
            gap: 15px 20px;
        }

        &__item {
            @include size(105px);
        }

        &__link {
            svg {
                @include size(65px);
            }
        }

        &__label {
            font-size: toRem(12);
            line-height: toRem(15);
        }
    }

    // Events
    .events {
        height: 225px;

        &__link {
            height: 175px;
            padding: 15px 20px;
            gap: 5px;
        }

        &__date {
            margin-bottom: 5px;
        }

        &__title {
            font-size: toRem(20);
            line-height: toRem(25);
        }

        &__button {
            font-size: toRem(14);
        }
    }

    // Posts
    .posts {
        @include size(460px, 225px);

        &__image {
            @include size(190px, 175px);

            & ~ .posts__content {
                width: 270px;
            }
        }

        &__content {
            @include size(100%, 175px);
            padding: 20px;
        }

        &__title {
            font-size: toRem(20);
            line-height: toRem(25);
        }

        &__button {
            font-size: toRem(14);
        }
    }
}


// 960
@media screen and (max-width: $medium) {


    body.home {
        min-height: unset;

        main {
            min-height: unset;
            height: auto;
            padding-bottom: 50px;
            padding-top: 115px;
        }

        .section {
            &--1{
                .container {
                    flex-direction: column;
                }
            }
        }
    }

    // Grande image
    .gdimage {
        display: none;
    }

    // Search Bar
    .search_home {
        width: 100%;
        margin-bottom: 40px;

        &__buttons {
            justify-content: flex-start;
            gap: 10px;
        }

        label {
            font-size: toRem(18);
            line-height: toRem(25);
        }

        .search-svg {
            background-color: $color-white;
        }
    }

    // Access Rapides
    .access {
        @include size(100%, auto);

        &__list {
            gap: 0;
            justify-content: space-between;
        }

        &__item {
            @include size(85px, 105px)
        }
    }

    // Events
    .events {
        width: 37%;
        height: 235px;

        &__link {
            width: 100%;
        }
    }

    // Posts
    .posts {
        width: 60%;
        height: 235px;

        &__image {
            width: 42%;
        }

        &__content {
            width: 58%;
        }
    }

}

// 800
@media screen and (max-width: 900px) {
    
    body.home {
        .section {
            &--2 {
                .container {
                    flex-direction: column;
                    row-gap: 50px;
                }
            }
        }
    }

    // Events
    .events {
        @include size(100%, auto);

        &__link {
            @include size(100%, auto);
            margin-bottom: 10px
        }
    }

    // Posts
    .posts {
        @include size(100%, auto);

        &__link {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
        }

        &__image {
            display: block;
            @include size(100%, auto);
            max-height: 250px;
            
            & ~ .posts__content {
                width: 100%;
            }
        }

        &__content {
            @include size(100%, auto);
        }
    }
}


// 640
@media screen and (max-width: $small) {


    // Access rapides
    .access {

        &__list {
            gap: 10px 0;
        }

        &__item {
            flex-basis: 30%;
            row-gap: 10px;
        }
    }
}
