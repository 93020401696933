body.home {
    .footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        padding: 0;
        overflow: hidden;
        background-color: $color-bg--transparent;
        border-radius: 0;

        &__container {
            position: relative;
            height: 75px;
            padding: 0;
            align-items: center;
            justify-content: flex-end;

            &:before {
                content: "";
                position: absolute;
                left: calc(50% - 50px);
                @include size(100vw, 100%);
                background-color: $color-third;
            }
        }

        .footer {
        
            &__menu {
                padding: 0;
                width: calc(50% + 15px);
                margin-top: 0;

                &__list {
                    gap: 5px 35px;
                }

                &__item {
                    padding: 0;
                }

                &__link {
                    font-size: toRem(12);
                    color: $color-text;
                }
            }
        }
    }
}

.footer {
    position: relative;
    background-color: $color-dark;
    border-radius: 50px 50px 0 0;
    padding-bottom: 50px;

    &__container {
        @include flex($wrap: wrap, $justifyContent: space-between, $alignItems: center);
        padding-top: 80px;
    }

    &__infos,
    &__opening-hours {
        @include flex($direction: column, $justifyContent: center,$alignItems: flex-start);
        color: $color-white;
    }

    &__infos {
        width: 225px;
    }

    &__opening-hours {
        width: 400px;
    }

    &__title {
        color: $color-white;
        @include font-size(22);
        font-weight: $font-weight-bold;
        margin-bottom: 15px;
    }

    &__phone {
        @include flex($alignItems: center);
        gap: 5px;
        color: $color-white;
        text-decoration: underline;
        text-decoration-color: $color-bg--transparent;
        transition: text-decoration-color $duration $timing;

        svg {
            @include size(26px);
            fill: $color-white;
        }

        &:hover, &:focus {
            color: $color-white;
            text-decoration: underline;
            text-decoration-color: $color-white;
        }
    }

    &__btn {
        color: $color-text;
        border-color: $color-white;
        background-color: $color-white;
        margin: 0;
        border: 0;

        svg {
            fill: $color-text;
            @include size(20px);
            margin-right: 10px;
        }

        &:hover, &:focus {
            color: $color-white;
            background-color: $color-main;

            svg {
                fill: $color-white;
            }
        }
    }

    &__menu {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 45px;

        &__list {
            @include flex($wrap: wrap, $alignItems: center, $justifyContent: flex-start);
            width: 100%;
            gap: 10px 60px;
        }

        &__item {
            position: relative;
            padding: 0;
        }

        &__link {
            color: $color-white;
            transition: opacity $duration $timing;

            &:hover, &:focus {
                color: $color-white;
                opacity: .6;
                text-decoration: none;
            }
        } 
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    body.home {

        .footer {
            .footer {
        
                &__container {
                    &:before {
                        left: calc(50% - 65px);
                    }
                }
            }
        }
    }

    footer {

        .footer {
            &__menu {
                &__list {
                    gap: 10px 35px;
                }
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    body.home {
        .footer {
            position: relative;
            border-radius: 0;

            .footer {
                &__menu {
                    @include size(100%, auto);
                    justify-content: space-between;
                    align-items: center;

                    &__list {
                        row-gap: 10px;
                    }

                    &__item {
                        margin: 0;
                        height: 20px;
                    }
                }
                
                &__container {
                    position: unset;
                    height: auto;
                    padding: 15px 0;

                    &:before {
                        left: 0;
                        right: 0;
                        width: 100%;
                    }
                }
            }
        }
    }

    .footer {
        border-radius: 25px 25px 0 0;

        &__container {
            gap: 30px;
        }

        &__logo {
            flex: none;
            width: 100%;
        }

        &__menu {
            &__list {
                justify-content: flex-start;
            }

            &__item {
                position: relative;
            }
        }
    }

}

// 900
@media screen and (max-width: 900px) {

    body.home {

        .footer {
            border-radius: 25px 25px 0 0;
            padding-bottom: 70px;
    
            .footer {
    
                &__container {    
                    &:before {
                        top: 0;
                    }
                }
            }
        }
    }
    
    .footer {    
        border-radius:  25px 25px 0 0;
        padding-bottom: 80px;
    }
}


// 640
@media screen and (max-width: $small) {

    .footer {
        &__infos,
        &__opening-hours {
            flex: none;
            width: 100%;
        }
    }

}


// 360
@media screen and (max-width: $small) {

    .footer {
        padding-bottom: 80px;

        &__menus {
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
        }
    }

}
